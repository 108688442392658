<template>
  <div class="hambuger-menu">
    <template v-if="menuType === 'burger'">
      <a @click="$eventBus.$emit('open-mobile-nav'); toggleMenu()">
        <Icon class="-size-28">
          <HamburgerMenuSVG></HamburgerMenuSVG>
        </Icon>
      </a>
    </template>
    <template v-else>
      <a @click="$eventBus.$emit('close-mobile-nav'); toggleMenu()">
        <Icon class="-size-28">
          <CloseSVG></CloseSVG>
        </Icon>
      </a>
    </template>
  </div>
</template>

<script>
export default {
  name: 'HamburgerMenu',
  data() {
    return {
      menuType: 'burger',
    };
  },
  methods: {
    toggleMenu() {
      if (this.menuType === 'burger') {
        this.menuType = 'close';
      } else {
        this.menuType = 'burger';
      }
    },
  },
  mounted() {
    this.$eventBus.$on('toggle-mobile-nav', this.toggleMenu);
  },
  components: {
    Icon: () => import('@/components/atoms/Icon.vue'),
    CloseSVG: () => import('@/assets/icons/close.svg'),
    HamburgerMenuSVG: () => import('@/assets/icons/hamburger-menu.svg'),
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/hamburger-menu.scss"></style>
